:root {
  --primary: #f06292;
}

body {
  background: var(--primary);
}

/* Extra-Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .category-selector {
    padding: 1em !important;
    margin: 1em !important;
  }

  .final-dialog {
    padding: 1em !important;
    margin: 1em !important;
  }

  .letter-maker {
    padding: 1em !important;
    margin: 1em !important;
    height: fit-content;
  }
  .letter-maker-desc {
    flex-direction: column;
  }
  .letter-maker-desc * {
    flex-basis: 100%;
  }
  .letter-maker-label {
    padding: 0em 2em !important;
  }
  .letter-maker-btn-container,
  .letter-maker-box-container {
    flex-basis: 100% !important;
  }

  /*Letter Reader*/

  .letter-reader {
    padding: 1em !important;
    margin: 1em !important;
    height: fit-content;
  }

  .letter-reader-desc {
    flex-direction: column;
  }
  .letter-reader-desc * {
    flex-basis: 100%;
  }
  .letter-reader-label {
    padding: 0em 2em !important;
  }
  .letter-reason-container {
    flex-basis: 100% !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.main {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.intro-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  margin: 1em;
}

.row {
  text-align: center;
}

.intro-emoji {
  font-size: 10em;
}
.intro-btn {
  text-decoration: none;
  color: black;
  background-color: white;
  padding: 1em;
  font-weight: 600;
  border-radius: 0.7em;
  box-shadow: 0.4em 0.4em rgb(0 0 0 / 16%);
  cursor: pointer;
  display: block;
  transition: 0.5s transform;
}
.intro-btn:hover {
  box-shadow: none;
  transform: translate(0.4em, 0.4em);
}

.intro-footer p {
  color: white;
  font-weight: 600;
}

.category-selector {
  padding: 3em;
  margin: 3em;
  border-radius: 1em;
  border: 1px solid #f10c59;
  background-color: white;
  max-width: 800px;
}

.category-selector-wrapper {
}

.category-selector-desc {
  text-align: center;
}

.category-selector-desc > p {
  font-weight: 500;
}

.category-btn {
  display: flex;
  border: 4px solid var(--primary);
  padding: 1em;
  margin: 1em;
  border-radius: 1em;
  background-color: #efefef;
  box-sizing: border-box;
  box-shadow: 0.4em 0.4em var(--primary);
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
}

.category-btn:hover,
.category-btn:focus {
  box-shadow: none;
  background-color: var(--primary);
  color: white;
  transform: translate(0.4em, 0.4em);
}

.category-emoji {
  font-size: 5em;
  margin-right: 20px;
}
.category-label {
  font-size: 1.2em;
  font-weight: 500;
}

.category-btn-container {
  flex: 1 1;
  flex-basis: 50%;
  box-sizing: border-box;
}
.category-btn-group {
  display: flex;
  flex-wrap: wrap;
}

.letter-maker-container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
}
.letter-maker {
  background-color: white;
  padding-top: 1em !important;
  padding: 3em;
  margin: 3em;
  border-radius: 1em;
  border: 1px solid #f10c59;
  width: 100%;
  max-width: 1000px;
}

.letter-maker-desc {
  display: flex;
  justify-content: space-between;
}
.letter-maker-emoji-container {
  text-align: center;
}

.letter-maker-emoji-container > span {
  font-size: 6em;
  flex: 0;
}
.letter-maker-label {
  flex: 1;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 2em;
  font-weight: 500;
}

.letter-maker-btn-group {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.letter-maker-btn-group a {
  display: flex;
  color: white;
  font-weight: 500;
  border: 4px solid var(--primary);
  padding: 1em;
  margin: 1em;
  border-radius: 1em;
  background-color: var(--primary);
  box-sizing: border-box;
  box-shadow: 0.4em 0.4em #d6d6d6;
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
  align-items: center;
}

.letter-maker-btn-group a.disabled {
  background-color: #efefef;
  color: gray;
  border-color: transparent;
}
.letter-maker-btn-group a:not(.disabled):hover {
  box-shadow: none;
  background-color: var(--primary);
  color: white;
  transform: translate(0.4em, 0.4em);
}

.letter-maker-btn-container,
.letter-maker-box-container {
  flex: 1 1;
  flex-basis: 33.3%;
  box-sizing: border-box;
}

.letter-maker-btn-container .letter-maker-btn {
  display: flex;
  border: 4px solid var(--primary);
  color: white;
  padding: 1em;
  margin: 1em;
  border-radius: 1em;
  background-color: var(--primary);
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  /*disable highlight*/
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.attention:not(.disabled) {
  animation: attention 2s infinite;
}
.attention:hover {
  animation-play-state: paused;
}

@keyframes attention {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.letter-maker-btn-container .letter-maker-btn:hover,
.letter-maker-btn-container .letter-maker-btn:focus {
  transform: scale(0.9);
}

.letter-maker-box-container .letter-maker-box:hover,
.letter-maker-box-container .letter-maker-box:focus {
  transform: scale(0.9);
}

.letter-maker-btn-container .letter-maker-btn.disabled {
  border: 4px solid #e0e0e0;
  color: #9e9e9e;
  background-color: #e0e0e0;
}

.letter-maker-btn-container .letter-maker-btn span {
  font-size: 4em;
}

.letter-maker-box-container .letter-maker-box {
  display: flex;
  border: 4px solid var(--primary);
  padding: 2em;
  margin: 1em;
  border-radius: 1em;
  background-color: #efefef;
  box-sizing: border-box;
  box-shadow: 0.4em 0.4em var(--primary);
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
  align-items: center;
  min-height: 108px;
  position: relative;
  box-sizing: border-box;
}

.letter-maker-box-container .letter-maker-box .letter-maker-remove-btn {
  padding: 1em;
  background-color: var(--primary);
  border-radius: 2em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: -1em;
  left: -1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.letter-maker-box-container .letter-maker-box .letter-maker-remove-btn span {
  font-size: 2em;
}

.letter-maker-box-container .letter-maker-box .letter-maker-inner {
  display: block;
}

.letter-maker-box-container
  .letter-maker-box
  .letter-maker-inner
  input.headerInput {
  display: block;
  font-size: 1.17em;
  font-weight: bold;
  background: transparent;
  border: none;
}

.letter-maker-box-container
  .letter-maker-box
  .letter-maker-inner
  input.paragraphInput {
  display: block;
  font-size: 1.17em;
  background: transparent;
  border: none;
}

.letter-maker-box-container .letter-maker-box .letter-maker-inner * {
  margin: 0;
  caret-color: var(--primary);
}

.letter-maker-box-container
  .letter-maker-box
  .letter-maker-inner
  *:empty:before {
  content: attr(data-placeholder);
  opacity: 0.6;
}

.letter-maker-box-container .letter-maker-box .letter-maker-inner *:focus {
  outline: 0;
}

.letter-maker-library {
  display: flex;
  flex-wrap: wrap;
}

.educational-prompt {
  padding: 3em;
  margin: 3em;
  border-radius: 1em;
  border: 1px solid #f10c59;
  background-color: white;
  max-width: 800px;
}

.educational-prompt-header {
  text-align: center;
}

.educational-prompt-header-emoji {
  font-size: 3em;
}
.educational-prompt-header-desc {
  font-weight: 500;
}

.educational-prompt-header-suggestions {
  margin-top: 1em;
}
.educational-prompt-suggestion-btn {
  background-color: var(--primary);
  color: white;
  padding: 1em;
  margin: 1em;
  font-weight: 600;
  border-radius: 0.7em;
  box-shadow: 0.4em 0.4em rgb(0 0 0 / 16%);
  cursor: pointer;
  display: inline-block;
  transition: 0.5s transform;
}

.educational-prompt-suggestion-btn:hover,
.educational-prompt-suggestion-btn:focus {
  box-shadow: none;
  background-color: var(--primary);
  color: white;
  transform: translate(0.4em, 0.4em) !important;
}

.educational-prompt-add-link-btn {
  background-color: #e91e63;
  color: white;
  padding: 1em;
  margin: 1em;
  font-weight: 600;
  border-radius: 0.7em;
  box-shadow: 0.4em 0.4em rgb(0 0 0 / 16%);
  cursor: pointer;
  display: inline-block;
  transition: 0.5s transform;
}

.educational-prompt-footer {
  display: flex;
  justify-content: stretch;
}

.educational-prompt-continue-btn {
  flex: 1;
  background-color: var(--primary);
  color: white;
  padding: 1em;
  margin: 1em;
  font-weight: 600;
  border-radius: 0.7em;
  box-shadow: 0.4em 0.4em rgb(0 0 0 / 16%);
  cursor: pointer;
  transition: 0.5s transform;
  text-align: center;
}

.educational-prompt-continue-btn {
  flex: 1;
  background-color: var(--primary);
  color: white;
  padding: 1em;
  margin: 1em;
  font-weight: 600;
  border-radius: 0.7em;
  box-shadow: 0.4em 0.4em rgb(0 0 0 / 16%);
  cursor: pointer;
  transition: 0.5s transform;
  text-align: center;
}

.educational-prompt-continue-btn.skippable {
  background-color: #eaeaea;
  color: black;
}

.educational-prompt-list-entry {
  display: flex;
}

.educational-prompt-list-entry-thumbnail {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.educational-prompt-list-entry-thumbnail img {
  width: 1.5em;
  height: 1.5em;
}

.educational-prompt-list-entry-url {
  flex: 1;
  display: flex;
}
.educational-prompt-list-entry-url input {
  flex-basis: 100%;
  border: 0;
  font-size: 1em;
  font-weight: 600;
}

.educational-prompt-list-entry-url input.valid-input {
  text-decoration: underline;
  color: #2196f3;
}
.educational-prompt-list-entry-url input:focus {
  outline: 0;
}
.educational-prompt-list-entry-btn-group {
  padding: 1em;
  cursor: pointer;
}

.educational-prompt-list-entry-btn-group span {
  font-weight: 600;
}

.final-dialog {
  position: relative;
  padding: 3em;
  margin: 3em;
  border-radius: 1em;
  border: 1px solid #f10c59;
  background-color: white;
  max-width: 800px;
  text-align: center;
}

.final-dialog .final-dialog-close-btn {
  padding: 1em;
  background-color: #f7f7f7;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  position: absolute;
  top: -1em;
  left: -1em;
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform;
}
.final-dialog .final-dialog-close-btn:hover {
  transform: scale(1.2) !important;
}

.final-dialog .final-dialog-close-btn > span {
  font-weight: 600;
  font-size: 2.5em;
}

.final-dialog .final-dialog-emoji {
  font-size: 8em;
}

.final-dialog-copy-btn {
  display: flex;
  color: white;
  font-weight: 600;
  border: 4px solid var(--primary);
  padding: 1em;
  margin: 1em;
  border-radius: 1em;
  background-color: var(--primary);
  box-sizing: border-box;
  box-shadow: 0.4em 0.4em #d6d6d6;
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
  width: fit-content;
}

.final-dialog-copy-btn.disabled {
  background-color: #efefef;
  color: gray;
  border-color: transparent;
}

.final-dialog-footer {
  display: flex;
  justify-content: center;
}

.final-dialog-desc {
  padding: 1em;
}

.final-dialog-desc span {
  font-weight: 500;
}

.final-dialog-link {
  background: #efefef;
  color: var(--primary);
  font-weight: 600;
  padding: 1em;
  border-radius: 1em;
  max-width: 300px;
  overflow-wrap: break-word;
  transition: 1s height;
}

.letter-reader-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.letter-reader {
  background-color: white;
  padding-top: 1em !important;
  padding: 3em;
  margin: 3em;
  border-radius: 1em;
  border: 1px solid #f10c59;
  /* width: 100%;
  max-width: 1000px; */
  will-change: auto;
}
.letter-reader.loading {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.letter-reader-desc {
  display: flex;
  justify-content: space-between;
}
.letter-reader-emoji-container {
  text-align: center;
}

.letter-reader-emoji-container > span {
  font-size: 6em;
  flex: 0;
}
.letter-reader-label {
  flex: 1;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 2em;
  font-weight: 500;
}

.letter-reason-container .letter-reason {
  display: flex;
  border: 4px solid var(--primary);
  padding: 2em;
  margin: 1em;
  border-radius: 1em;
  background-color: #efefef;
  box-sizing: border-box;
  box-shadow: 0.4em 0.4em var(--primary);
  align-items: center;
  cursor: pointer;
  transition: 0.5s transform, 0.1s background-color, 0.1s color;
  align-items: center;
  min-height: 108px;
  position: relative;
  box-sizing: border-box;
  will-change: auto;
}

.letter-reason-container .letter-reason .letter-reason-inner {
  display: block;
}
.letter-reader-library {
  display: flex;
  flex-wrap: wrap;
}

.letter-reason-container {
  flex: 1 1;
  flex-basis: 33.3%;
  box-sizing: border-box;
}

.letter-reader-link {
  padding: 0.5em 0em;
}
.letter-reader-link a {
  color: #2196f3;
  font-weight: 700;
  word-wrap: break-word;
}
.letter-reader-linklist-heading {
  text-align: center;
}

@keyframes ldio-vm6kcz5uvjm {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-vm6kcz5uvjm div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 8px solid #f06292;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-vm6kcz5uvjm div {
  animation: ldio-vm6kcz5uvjm 1.923076923076923s linear infinite;
  top: 100px;
  left: 100px;
}
.loadingio-spinner-rolling-92gbw0a1ack {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
}
.ldio-vm6kcz5uvjm {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vm6kcz5uvjm div {
  box-sizing: content-box;
}
